import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Typography } from "@material-ui/core";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "typography"
    }}>{`Typography`}</h1>
    <blockquote style={{
      borderLeft: '4px solid red'
    }}>
    Use only fonts specified in this guide for web and application development.
    </blockquote>
    <h3 {...{
      "id": "explanation"
    }}>{`Explanation`}</h3>
    <p>{`Progress uses three distint typographys for all web and application development. Two typographys are used across all platforms while the third is to be used for blogging and heavy content areas so that it is easy and quick to read for our consumers.
The typographys are pulled from `}<a parentName="p" {...{
        "href": "https://fonts.google.com"
      }}>{`Google Fonts`}</a>{` and prefetched in both Gatsbyjs and Nextjs to allow for faster load times and a better user experience.`}</p>
    <h3 {...{
      "id": "installation"
    }}>{`Installation`}</h3>
    <p>{`Fonts should already be loaded into the Gatsbyjs framework pulled from bitbucket.  But if they are not use the following command to install google font prefetching and load the progress-ui fonts.`}</p>
    <p>{`With npm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install --save gatsby-plugin-prefetch-google-fonts
`}</code></pre>
    <p>{`With yarn:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`yarn add gatsby-plugin-prefetch-google-fonts
`}</code></pre>
    <h3 {...{
      "id": "usage"
    }}>{`Usage`}</h3>
    <p>{`In the `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{` file, load the plugin along with the progress-ui web-fonts.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
    plugins: [
        {
        resolve: \`gatsby-plugin-prefetch-google-fonts\`,
            options: {
                fonts: [
                    {
                        family: \`PT Sans Narrow\`, // Headings - H1, H2, H3, H4, H5, H6
                        variants: [\`regular\`, \`bold\`]
                    },
                    {
                        family: \`Source Sans Pro\`, // Text & Subheadings
                        variants: [\`light\`, \`regular\`, \`semi-bold\`]
                    },
                    {
                        family: \`Source Serif Pro\` // Reading Text
                    }
                ]
            }
        }
    ]
}
`}</code></pre>
    <h3 {...{
      "id": "component"
    }}>{`Component`}</h3>
    <p>{`Check usage of `}<a parentName="p" {...{
        "href": "https://material-ui.com/components/typography/#typography"
      }}>{`Typography API`}</a>{` on Material-UI website. Below you will find which fonts correlate to each heading and other variant types.`}</p>
    <blockquote>
    <Typography gutterBottom style={{
        marginBottom: "15px"
      }} mdxType="Typography">PT Sans Narrow - Bold</Typography>
    <Typography style={{
        fontFamily: "PT Sans Narrow",
        fontWeight: "bold",
        fontStyle: "normal"
      }} variant="h1" component="h1" mdxType="Typography">Heading H1</Typography><br />
    <Typography style={{
        fontFamily: "PT Sans Narrow",
        fontWeight: "bold",
        fontStyle: "normal"
      }} variant="h2" component="h2" mdxType="Typography">Heading H2</Typography><br />
    <Typography style={{
        fontFamily: "PT Sans Narrow",
        fontWeight: "bold",
        fontStyle: "normal"
      }} variant="h3" component="h3" mdxType="Typography">Heading H3</Typography><br />
    <Typography style={{
        fontFamily: "PT Sans Narrow",
        fontWeight: "bold",
        fontStyle: "normal"
      }} variant="h4" component="h4" mdxType="Typography">Heading H4</Typography><br />
    <Typography style={{
        fontFamily: "PT Sans Narrow",
        fontWeight: "bold",
        fontStyle: "normal"
      }} variant="h5" component="h5" mdxType="Typography">Heading H5</Typography><br />
    <Typography style={{
        fontFamily: "PT Sans Narrow",
        fontWeight: "bold",
        fontStyle: "normal"
      }} variant="h6" component="h6" mdxType="Typography">Heading H6</Typography>
    </blockquote>
    <h4 {...{
      "id": "subheadings"
    }}>{`Subheadings`}</h4>
    <blockquote>
    <Typography gutterBottom style={{
        marginBottom: "15px"
      }} mdxType="Typography">Source Sans Pro - Semi-bold</Typography>
    <Typography style={{
        fontFamily: "Source Sans Pro",
        fontWeight: "semi-bold",
        fontStyle: "normal"
      }} variant="subtitle1" mdxType="Typography">Subheading 1</Typography>
    <Typography style={{
        fontFamily: "Source Sans Pro",
        fontWeight: "semi-bold",
        fontStyle: "normal"
      }} variant="subtitle2" mdxType="Typography">Subheading 2</Typography>
    </blockquote>
    <h4 {...{
      "id": "text"
    }}>{`Text`}</h4>
    <blockquote>
    <Typography gutterBottom style={{
        marginBottom: "15px"
      }} mdxType="Typography">Source Sans Pro - Regular</Typography>
    <Typography style={{
        fontFamily: "Source Sans Pro",
        fontWeight: "regular",
        fontStyle: "normal",
        marginBottom: "15px"
      }} variant="subtitle1" gutterBottom mdxType="Typography">
        Body 1.<br />
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.</Typography>
    <Typography style={{
        fontFamily: "Source Sans Pro",
        fontWeight: "regular",
        fontStyle: "normal"
      }} variant="subtitle2" gutterBottom mdxType="Typography">
        Body 2.<br /> 
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
    </Typography>
    <Typography style={{
        fontFamily: "Source Sans Pro",
        fontWeight: "regular",
        fontStyle: "normal"
      }} variant="button" gutterBottom mdxType="Typography">
        BUTTON TEXT
    </Typography><br />
    <Typography style={{
        fontFamily: "Source Sans Pro",
        fontWeight: "regular",
        fontStyle: "normal"
      }} variant="caption" gutterBottom mdxType="Typography">
        Caption text
    </Typography><br />
    <Typography style={{
        fontFamily: "Source Sans Pro",
        fontWeight: "regular",
        fontStyle: "normal"
      }} variant="overline" gutterBottom mdxType="Typography">
        Overline text
    </Typography>
    </blockquote>
    <h4 {...{
      "id": "reading-text-blogs-manuals-etc"
    }}>{`Reading Text (Blogs, Manuals, etc.)`}</h4>
    <blockquote>
    <Typography gutterBottom style={{
        marginBottom: "15px"
      }} mdxType="Typography">Source Serif Pro</Typography>
    <Typography style={{
        fontFamily: "Source Serif Pro",
        fontStyle: "normal",
        marginBottom: "15px"
      }} variant="subtitle1" gutterBottom mdxType="Typography">
        Body 1.<br />
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.</Typography>
    <Typography style={{
        fontFamily: "Source Serif Pro",
        fontStyle: "normal"
      }} variant="subtitle2" gutterBottom mdxType="Typography">
        Body 2.<br /> 
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
    </Typography>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      